import React, { FC } from 'react';
import { graphql, PageProps, Link } from 'gatsby';
import classNames from 'classnames';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Layout from 'components/Layout';
import NFAccordion from 'components/common/NFAccordion';
import Banner from 'components/Banner';

import { parseBanner } from 'components/Banner/parsers';
import { getNestedSitemapPages, getNestedSymptomsPages } from './parsers';

import './sitemapPage.scss';
import './sitemapOverride.scss';

interface ISitemapPage extends PageProps {
  pageContext: { breadcrumbs: UmbracoTypes.IBreadCrumb[] };
  data: SitemapTypes.ISitemap;
}

const SitemapPage: FC<ISitemapPage> = ({
  pageContext: { breadcrumbs },
  data: {
    sitemap: {
      seoMetaTitle,
      seoMetaDescription,
      seoMetaKeywords,
      seoExternalHreflangs,
      seoContentType,
      sitemapBanner,
      lang,
    },
    symptomsLinks,
    header,
    footer,
    siteSettings,
    covidBanner,
    newsletterPopupDefault,
    newsletterPopupDiscount,
    newsletterPopupFreeDelivery,
    disclaimerPopup,
    searchPath,
    brandSettings,
    legalPopup,
    pageLangs: { nodes: langs },
    ...sitemapLinks
  },
}) => {
  const lastSlashIndex = siteSettings.siteUrl.lastIndexOf('/');
  const langFromUrl = siteSettings.siteUrl.substring(lastSlashIndex + 1);
  const nestedSitemapPages = getNestedSitemapPages(sitemapLinks, langFromUrl);
  const nestedSymptomsPages = getNestedSymptomsPages(symptomsLinks.navItems);
  const sitemapPages = [...nestedSymptomsPages, ...nestedSitemapPages];

  const renderNestedSitemapLinks = (item, index) => {
    let nestedPageItem;

    if (item?.children?.length) {
      nestedPageItem = (
        <div className="sitemap-page__accordion">
          <NFAccordion
            selectedIndex={-1}
            headerClassName={`accordion-title-depth-${item.depth}`}
            items={[
              {
                header: item.title,
                content: (
                  <ul
                    className={`nested-links__children-list nested-links__children-list--${item.depth}`}
                  >
                    {item.children.map((child) => (
                      <li
                        className={classNames({
                          'no-nested-title': child.title.trim().length === 0,
                        })}
                        key={child?.name}
                      >
                        {renderNestedSitemapLinks(child, index)}
                      </li>
                    ))}
                  </ul>
                ),
              },
            ]}
          />
        </div>
      );
    } else {
      nestedPageItem = (
        <p className="nested-links__title" key={item?.link}>
          <Link to={item?.link}>{item.title}</Link>
        </p>
      );
    }

    return nestedPageItem;
  };

  const sitemapLinksMarkup = sitemapPages.map((page, index) => {
    return renderNestedSitemapLinks(page, index);
  });

  return (
    <Layout
      seo={{
        seoMetaTitle,
        seoMetaDescription,
        seoMetaKeywords,
        seoExternalHreflangs,
        seoContentType,
      }}
      header={header}
      footer={footer}
      siteSettings={siteSettings}
      covidBanner={covidBanner}
      newsletterPopupDefault={newsletterPopupDefault}
      newsletterPopupDiscount={newsletterPopupDiscount}
      newsletterPopupFreeDelivery={newsletterPopupFreeDelivery}
      disclaimerPopup={disclaimerPopup}
      searchPath={searchPath}
      brandSettings={brandSettings}
      legalPopup={legalPopup}
      langSettings={{
        currentLang: lang,
        langs,
      }}
    >
      <div className="sitemap-page">
        <Banner
          {...parseBanner(sitemapBanner?.[0]?.properties)}
          bgBannerColor={
            sitemapBanner?.[0]?.properties?.pageBannerBannerBgColor?.[0]?.properties?.colorPicker
              ?.label || 'blue'
          }
          breadcrumbs={breadcrumbs}
        />
        <div className="rounded-container">
          <Container fluid>
            <Row>
              <Col lg={12}>
                <div className="nested-links">{sitemapLinksMarkup}</div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query($lang: String!, $pageBannerImageSize: Int = 1000, $pageIdRegex: String) {
    sitemap(lang: { eq: $lang }) {
      link
      lang
      ...FragmentSeo
      sitemapBanner {
        properties {
          ...FragmentBanner
        }
      }
    }
    allUmbracoProduct(filter: { lang: { eq: $lang } }) {
      nodes {
        link
        pageName
        pageNameReserved
      }
    }
    allUmbracoArticles(filter: { lang: { eq: $lang } }) {
      nodes {
        pageName
        pageNameReserved
        link
      }
    }
    allUmbracoProductList(filter: { lang: { eq: $lang } }) {
      nodes {
        pageName
        pageNameReserved
        link
      }
    }
    allUmbracoArticleListing(filter: { lang: { eq: $lang } }) {
      nodes {
        pageName
        pageNameReserved
        link
      }
    }
    allUmbracoUnbrandedArticle(filter: { lang: { eq: $lang } }) {
      nodes {
        pageName
        pageNameReserved
        link
      }
    }
    umbracoChildrenHub(lang: { eq: $lang }) {
      pageName
      pageNameReserved
      link
    }
    childrenDosage(lang: { eq: $lang }) {
      pageName
      pageNameReserved
      link
    }
    whyNurofen(lang: { eq: $lang }) {
      pageName
      pageNameReserved
      link
    }
    allContactUs(filter: { lang: { eq: $lang } }) {
      nodes {
        pageName
        pageNameReserved
        link
      }
    }
    covidPage(lang: { eq: $lang }) {
      pageName
      pageNameReserved
      link
    }
    usefulLinks(lang: { eq: $lang }) {
      link
      pageName
      pageNameReserved
    }
    podcastsPage(lang: { eq: $lang }) {
      link
      pageName
      pageNameReserved
    }
    allLdsPage(filter: { lang: { eq: $lang } }) {
      nodes {
        pageName
        pageNameReserved
        link
      }
    }
    allUmbracoUnbrandedArticleListing(filter: { lang: { eq: $lang } }) {
      nodes {
        pageName
        pageNameReserved
        link
      }
    }
    umbracoWhereToBuy(lang: { eq: $lang }) {
      pageName
      pageNameReserved
      link
    }
    symptomsLinks: header(lang: { eq: $lang }) {
      navItems {
        properties {
          items {
            properties {
              dropdown {
                properties {
                  simpleLink {
                    url
                    name
                  }
                  pageNameReserved
                }
              }
              title
              pageNameReserved
            }
          }
          title
          pageNameReserved
        }
      }
    }
    allCampaignPage(filter: { lang: { eq: $lang } }) {
      nodes {
        pageName
        pageNameReserved
        link
      }
    }
    allPromotionCampaignPage(filter: { lang: { eq: $lang } }) {
      nodes {
        pageName
        pageNameReserved
        link
      }
    }
    pageLangs: allSitemap(filter: { umbracoId: { regex: $pageIdRegex } }) {
      nodes {
        link
        lang
      }
    }
    allCampaignPagePlain(filter: { lang: { eq: $lang } }) {
      nodes {
        pageName
        pageNameReserved
        link
      }
    }
    ...FragmentCommonCompositions
  }
`;

export default SitemapPage;
